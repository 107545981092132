import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Input from "./Input"
import styled from "styled-components"
import { FlexContainer } from "../../styles/layout.styled"
import { CONTACT_INPUT_SCHEMA } from "./config"
import Alert from "../reusable/Alert"
import useAlert from "../../hooks/useAlert"

const ContactFormInput = styled(FlexContainer)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 0 0;
  width: 60%;

  .formHeading {
    font-size: 20px;
    color: ${({ theme }) => theme.lightBlue};
    font-weight: bolder;
    margin: 0 0 35px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 100%;
  }
`

const StyleButton = styled.button`
  width: 50%;
  padding: 14px 0;
  font-size: 18px;
  background-color: ${({ theme }) => theme.darkBlue};
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`

const TextAreaContainer = styled(FlexContainer)`
  margin-bottom: 46px;
`

const Span = styled.span`
  color: ${({ theme: { error } }) => error};
  position: absolute;
  left: 15px;
  bottom: -20px;
  font-size: 12px;
  visibility: ${({ showError }) => (showError ? "visible" : "hidden")};
  opacity: ${({ showError }) => (showError ? "1" : "0")};
  transition: all 0.3s ease;
`

const StyledTextArea = styled.textarea`
  border: none;
  border-bottom: 1px solid
    ${({ theme: { error }, showError }) =>
      showError ? error : "rgba(18, 36, 54, 0.3)"};
  font-size: 16px;
  width: 100%;
  padding: 0 0 0 15px;
  resize: none;
  font-family: "Poppins", sans-serif;
  transition: all 0.3s ease;

  &::placeholder {
    font-weight: 100;
    font-size: 14px;
    color: ${({ theme: { error }, showError }) =>
      showError ? error : "rgba(18, 36, 54, 0.3)"};
  }
  &:focus {
    outline: none;
    border-bottom: 1px solid
      ${({ theme: { lightBlue, error }, showError }) =>
        showError ? error : lightBlue};
    &::placeholder {
      color: ${({ theme: { lightBlue, error }, showError }) =>
        showError ? error : lightBlue};
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
  }
`

const StyleForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

export default function Form() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CONTACT_INPUT_SCHEMA),
  })

  const [alert, setAlert] = useAlert()

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = async data => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "customer", ...data }),
    })
      .then(res => {
        setAlert({
          alert: true,
          type: "success",
          message: "Your message was sent successfully",
        })
        console.log(res)
        reset()
      })
      .catch(error => {
        setAlert({
          alert: false,
          type: "error",
          message: "There's something wrong please try again later",
        })
        console.log(error)
      })
  }

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <ContactFormInput>
      <p className="formHeading">Leave us a message</p>
      <StyleForm
        name="customer"
        netlify
        data-netlify
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          register={register}
          placeholder="First Name"
          name="firstName"
          errors={errors}
        />
        <Input
          register={register}
          name="lastName"
          placeholder="Last Name"
          errors={errors}
        />
        <Input
          register={register}
          name="email"
          placeholder="Email Address"
          errors={errors}
        />
        <Input
          register={register}
          name="phoneNumber"
          placeholder="Phone Number"
          errors={errors}
        />
        <Input
          register={register}
          name="companyName"
          placeholder="Company Name"
          errors={errors}
        />
        <Input
          register={register}
          name="projectTitle"
          placeholder="Project Title"
          errors={errors}
        />
        <Input
          register={register}
          name="typeOfProject"
          placeholder="Type of project"
          errors={errors}
        />
        <Input
          register={register}
          name="budget"
          placeholder="Budget"
          errors={errors}
        />
        <TextAreaContainer>
          <StyledTextArea
            {...register("description")}
            name={"description"}
            rows="5"
            cols="70"
            placeholder="Description"
            showError={errors && errors["description"]}
          />
          <Span showError={errors && errors["description"]}>
            {errors && errors["description"] && errors["description"].message}
          </Span>
        </TextAreaContainer>
        <FlexContainer>
          <StyleButton type="submit">Send</StyleButton>
        </FlexContainer>
      </StyleForm>
      <Alert message={alert.message} type={alert.type} alert={alert.alert} />
    </ContactFormInput>
  )
}
