import { useEffect, useState } from "react"

export default function useAlert(
  options = {
    alert: false,
    message: "",
    type: "",
  },
  timer = 4000
) {
  const [alert, setAlert] = useState(options)
  useEffect(() => {
    let timerId
    timerId = setTimeout(() => {
      if (alert) {
        setAlert(prev => ({ ...prev, alert: false }))
      }
    }, timer)

    return () => clearTimeout(timerId)
  }, [alert, timer])

  return [alert, setAlert]
}
