import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../styles/layout.styled"

const ContactInfoContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 18%;

  .bold-text {
    color: ${({ theme }) => theme.lightBlue};
    font-weight: bolder;
    font-size: 20px;
    margin: 0;
  }

  .text {
    text-decoration: none;
    color: ${({ theme }) => theme.lightBlue};
    font-size: 14px;
    margin: 10px 0 0 0;
    font-weight: 100;
  }
  .underlined {
    text-decoration: underline;
  }

  .bottom-text {
    margin: 110px 0 0 0;
  }

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 100%;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-evenly;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    .bold-text {
      font-size: 14px;
    }
    .text {
      font-size: 12px;
    }
    .bottom-text {
      margin: 0;
    }
  }
`

const ContactInfo = () => {
  return (
    <ContactInfoContainer>
      <FlexContainer
        flexDirection="column"
        alignItems="flex-start"
        tabletWidth="48%"
        mobileMargin="30px 0 30px 0"
      >
        <FlexContainer flexDirection="column" alignItems="flex-start">
          <p className="bold-text">Send us your RFP</p>
          <a
            href="mailto: info@megproduction.com"
            target="_blank"
            rel="noreferrer nooper"
            className="text underlined"
          >
            info@megproduction.com
          </a>
        </FlexContainer>
        {/*<FlexContainer*/}
        {/*  flexDirection="column"*/}
        {/*  alignItems="flex-start"*/}
        {/*  margin="32px 0 0"*/}
        {/*>*/}
        {/*  <p className="bold-text">Want to say hi?</p>*/}
        {/*  <a*/}
        {/*    href="mailto: hello@megproduction.com"*/}
        {/*    target="_blank"*/}
        {/*    rel="noreferrer nooper"*/}
        {/*    className="text underlined"*/}
        {/*  >*/}
        {/*    hello@megproduction.com*/}
        {/*  </a>*/}
        {/*</FlexContainer>*/}
        <FlexContainer
          flexDirection="column"
          alignItems="flex-start"
          margin="32px 0 0"
        >
          <p className="bold-text">We’re hiring</p>
          <a
            href="mailto: info@megproduction.com"
            target="_blank"
            rel="noreferrer nooper"
            className="text underlined"
          >
            Tell us about yourself
          </a>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        flexDirection="column"
        alignItems="flex-start"
        tabletWidth="48%"
      >
        <p className="bottom-text bold-text">Get in Touch</p>
          <a
            href="tel:+374 96 345245"
            target="_blank"
            rel="noreferrer nooper"
            className="text"
          >
           Phone: <br/> +374 96 345245
          </a>
          <a
            href="tel:+374 44 345245"
            target="_blank"
            rel="noreferrer nooper"
            className="text"
          >
            +374 44 345245
          </a>
        <p className="text">
          Email:
          <br />
          <a
            className="text"
            href="mailto: info@megproduction.com"
            target="_blank"
            rel="noreferrer nooper"
          >
            info@megproduction.com
          </a>
        </p>
      </FlexContainer>
    </ContactInfoContainer>
  )
}

export default ContactInfo
