import * as yup from "yup"

export const CONTACT_INPUT_SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .required("is a required field")
    .min(4, "must be more then 4 characters"),
  lastName: yup
    .string()
    .required("is a required field")
    .min(4, "must be more then 4 characters"),
  email: yup
    .string()
    .required("is a required field")
    .email("must be a valid email"),
  phoneNumber: yup
    .string()
    .required("is a required field")
    .test("phoneNumber", "must be a valid phone number", value => {
      const regx = /^[+]*[(]?[0-9]{1,4}[)]?[-\s/0-9]*$/
      return regx.test(value)
    }),

  companyName: yup
    .string()
    .required("is a required field")
    .min(6, "must be more then 6 characters"),
  projectTitle: yup
    .string()
    .required("is a required field")
    .min(6, "must be more then 6 characters"),
  typeOfProject: yup
    .string()
    .required("is a required field")
    .min(6, "must be more then 6 characters"),
  budget: yup
    .string()
    .required("is a required field")
    .test("phoneNumber", "must be a valid price", value => {
      const regx = /[+-]?[0-9]+/
      return regx.test(value)
    }),

  description: yup
    .string()
    .required("is a required field")
    .min(6, "must be more then 6 characters"),
})
