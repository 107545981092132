import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../styles/layout.styled"

const InputContainer = styled(FlexContainer)`
  width: 47%;
  margin: ${({ margin }) => margin || "0 0 40px 0"};
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`

const StyledInput = styled.input`
  padding: ${({ padding }) => padding || "15px 0 10px 15px"};
  font-size: 16px;
  border: none;
  border-bottom: 1px solid
    ${({ showError, theme: { error } }) =>
      showError ? error : "rgba(18, 36, 54, 0.3)"};
  background: ${({ bg }) => bg || "transparent"};
  width: 100%;
  transition: all 0.3s ease;

  &::placeholder {
    font-weight: 100;
    font-size: 14px;
    color: ${({ theme: { error }, showError }) =>
      showError ? error : "rgba(18, 36, 54, 0.3)"};
  }
  &:focus {
    outline: none;
    border-bottom: 1px solid
      ${({ theme: { lightBlue, error }, showError }) =>
        showError ? error : lightBlue};
    &::placeholder {
      color: ${({ theme: { lightBlue, error }, showError }) =>
        showError ? error : lightBlue};
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
  }
`

const ErrorMessage = styled.span`
  color: ${({ theme: { error } }) => error};
  position: absolute;
  left: 15px;
  bottom: -20px;
  font-size: 12px;
  visibility: ${({ showError }) => (showError ? "visible" : "hidden")};
  opacity: ${({ showError }) => (showError ? "1" : "0")};
  transition: all 0.3s ease;
`

export default function Input({
  type = "text",
  name,
  placeholder,
  register,
  errors,
}) {
  return (
    <InputContainer>
      <StyledInput
        showError={errors && errors[name]}
        {...register(name)}
        type={type}
        name={name}
        placeholder={placeholder}
      />
      <ErrorMessage showError={errors && errors[name]}>
        {errors && errors[name] && errors[name].message}
      </ErrorMessage>
    </InputContainer>
  )
}
